import ShopaholicProductList from "@lovata/shopaholic-product-list/shopaholic-product-list";
import ShopaholicPagination from "@lovata/shopaholic-product-list/shopaholic-pagination";
import ShopaholicSorting from "@lovata/shopaholic-product-list/shopaholic-sorting";
import ShopaholicFilterPanel from "@lovata/shopaholic-filter-panel/shopaholic-filter-panel";

export default new class catalogContent {
    constructor() {
        this.preLoaderSelector = '.preloader';
        this.sCatalogWrapper = 'catalog_wrapper';
        this.sFilterWrapper = 'filter_wrapper';
        this.sBrandFilterWrapper = '._shopaholic-brand-filter-wrapper';
        this.sCategoryFilterWrapper = '._shopaholic-category-filter-wrapper';

        this.isFaviritesPage = document.body.getAttribute('data-page-id') === 'profile-favorites';
        this.isSearchPage = document.body.getAttribute('data-page-id') === 'search';

        this.init();
    }
    init () {
        const obListHelper = new ShopaholicProductList();
        obListHelper.setAjaxRequestCallback((obRequestData) => {
            if (this.isFaviritesPage) {
                obRequestData.update = {
                    'profile/favorite-list': `#top`,
                };
            } else if (this.isSearchPage) {
                obRequestData.update = {
                    'search/result': `#catalog`,
                };
            }
            else {
                obRequestData.update = {
                    'shop/catalog': `.${this.sCatalogWrapper}`,
                    'catalog/filter': `.${this.sFilterWrapper}`,
                };
            }
            obRequestData.loading = this.preLoaderSelector;
            obRequestData.complete = () => {
                this.updateAjaxComplete();
            };

            return obRequestData;
        });

        const obPaginationHelper = new ShopaholicPagination(obListHelper);
        obPaginationHelper.init();
        const obSortingHelper = new ShopaholicSorting(obListHelper);
        obSortingHelper.init();
        const obFilterPanel = new ShopaholicFilterPanel(obListHelper);
        obFilterPanel.init();
        const obBrandFilterPanel = new ShopaholicFilterPanel(obListHelper);
        obBrandFilterPanel.setWrapperSelector(this.sBrandFilterWrapper).setFieldName('brand').init();
        const obCategoryFilterPanel = new ShopaholicFilterPanel(obListHelper);
        obCategoryFilterPanel.setWrapperSelector(this.sCategoryFilterWrapper).setFieldName('category').init();
    }
    updateAjaxComplete() {
        $('body, html').animate({ scrollTop: $('#top').offset().top}, 800);
    }
}();

